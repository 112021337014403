











import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {}
})

export default class ScrollToTop extends Vue {

  private scTimer = 0
  private scY = 0

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  }

  private handleScroll() {
    if (this.scTimer) {
      return
    }
    this.scTimer = setTimeout(() => {
      this.scY = window.scrollY
      clearTimeout(this.scTimer)
      this.scTimer = 0
    }, 100)
  }

  private toTop() {
    window.scrollTo({top: 0, behavior: "smooth"})
  }
}
