




































































































import {Component, Vue} from "vue-property-decorator";
import PatternFilter from '@/components/PatternFilter.vue'
import {round} from '@amcharts/amcharts4/.internal/core/utils/Math';
import ScrollToTop from "@/components/ScrollToTop.vue";

@Component({
  components: {ScrollToTop, PatternFilter},
})

export default class Cards extends Vue {

  private placeholder = require('@/assets/pervaSafeLogo.png')
  private web_backend = 'https://hci.hochschule-trier.de:8443/files/'

  //Pattern Filter & Search Bar
  private filterStage = '-1'
  private patterns = []
  private filteredPatterns = [] as any
  private search = ''

  // Pattern Data:s
  private id = 0
  private name = ''
  private principle = ''
  private problem = ''
  private situation = ''
  private solution = ''
  private implDetails = ''
  private usesPatterns = []
  private similarPatterns = []
  private combinesPatterns = []
  private stage = 0
  private image = ''

  private accordingComments = []
  private averageRating = 0
  private allRatings = []
  private numberRatings = 0

  private ids = []
  private patternNames = [] as any
  private commentPatterns = []
  private commentCount = [] as any
  private commentRatings = []
  private avratings = [] as any

  private passPatternData(name: string) {
    this.$emit('emitPatternData', {patternName: name, webpage: 'Cards'})
  }

  private gotoAddMode() {
    this.$emit('addMode', true)
    this.$emit('emitPatternData', {patternName: '', webpage: 'Cards'})
  }

  /*Search Bar begin...*/
  private filterPattern() {
    this.filteredPatterns = this.patterns
    this.filteredPatterns = this.patterns.filter((c: any) => (c.stage == this.filterStage))
  }

  private filterPatternNames() {
    this.filteredPatterns = this.patterns.filter((c: any) =>
        (c.name.toLowerCase().includes(this.search.toLowerCase().trim())))
  }

  private async removePatternFilter() {
    Vue.axios.get(`${this.$store.state.root}/api/patterns`).then(resp => {
      this.patterns = resp.data
      this.filteredPatterns = this.patterns.filter((c: any) => (c.stage !== 4))

      this.filteredPatterns.forEach((pattern: any) => {
        pattern.image = this.web_backend + pattern.image
      })
    })
  }

  private async getPatterns() {
    Vue.axios.get(`${this.$store.state.root}/api/patterns`).then(resp => {
      this.patterns = resp.data
    })
  }

  private getStageName(stage: number) {
    switch (stage) {
      case 0:
        return 'Created'
      case 1:
        return 'Consideration'
      case 2:
        return 'Candidate'
      case 3:
        return 'Approved'
      case 4:
        return 'Rejected'
      default:
        return '-'
    }
  }

  // sets filterStage to value from dropdown in PatternFilter.vue
  private setFilterStage(stage: string) {
    this.filterStage = stage
    if (this.filterStage == '-1') {
      this.removePatternFilter()
    } else {
      this.filterPattern()
    }
  }

  /*... Search Bar end */

  private getCommentsCount(name: String) {
    return this.commentCount[this.patternNames.indexOf(name)]
  }

  private getAverageRating(name: String) {
    return this.avratings[this.patternNames.indexOf(name)]
  }

  private shortenDetailText(text: String) {
    if (text == null || text == '' || text.length < 150) {
      return text
    }
    return text.slice(0, 150) + " ..."
  }


  private async setPatternData(allResponses: Response[]) {
    const patternData = await allResponses[0].json()
    this.stage = parseInt(patternData.stage)
    this.name = patternData.name
    this.principle = patternData.principle
    this.problem = patternData.problem
    this.situation = patternData.situation
    this.solution = patternData.solution
    this.implDetails = patternData.implementation
    if (patternData.similar != null) {
      this.similarPatterns = patternData.similar.split(', ')
    } else {
      this.similarPatterns = []
    }
    if (patternData.uses != null) {
      this.usesPatterns = patternData.uses.split(', ')
    } else {
      this.usesPatterns = []
    }
    if (patternData.combines != null) {
      this.combinesPatterns = patternData.combines.split(', ')
    } else {
      this.combinesPatterns = []
    }

    this.ids = patternData.map((c: any) => c.patternId as [])
    this.patternNames = patternData.map((n: any) => n.name as [])

    const commentData = await allResponses[1].json()
    this.accordingComments = commentData.filter((c: any) => (c.pattern == this.id))
    this.allRatings = this.accordingComments.map((int: any) => int.ratingRelevance) as []
    const sum = this.allRatings.reduce((a: any, b: any) => a + b, 0)
    this.averageRating = (sum / this.allRatings.length) || 0
    this.numberRatings = this.allRatings.length

    this.commentPatterns = commentData.map((i: any) => i.pattern as [])
    this.commentRatings = commentData.map((r: any) => r.ratingRelevance as [])

    //  Count how many comments are in database for each pattern
    for (let i = 0; i < this.ids.length; i++) {
      let tmp = 0
      for (let j = 0; j < this.commentPatterns.length; j++) {
        if (this.ids[i] == this.commentPatterns[j]) {
          tmp++
        }
      }
      this.commentCount.push(tmp)
    }

    // calculate aver. rating for each Pattern
    for (let i = 0; i < this.ids.length; i++) {
      let rating = 0
      let anz = 0
      for (let j = 0; j < this.commentPatterns.length; j++) {
        if (this.ids[i] == this.commentPatterns[j]) {
          rating += this.commentRatings[j]
          anz++
        }
      }
      let tmp = round(rating / anz, 1)
      if (Number.isNaN(tmp)) {
        tmp = 0
      }
      this.avratings.push(tmp)
    }
  }

  private async getData() {
    Promise.all([
      fetch(`${this.$store.state.root}/api/patterns/`, {
        method: 'GET'
      }),
      fetch(`${this.$store.state.root}/api/comments/`, {
        method: 'GET'
      })
    ]).then(async allResponses => {
      await this.setPatternData(allResponses)
    })
  }

  created() {
    this.getData()
    this.removePatternFilter()
  }

}
